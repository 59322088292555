<template>
  <div class="flex items-center justify-between text-sm leading-10 text-[#D9D9D9]">
    <p class="pl-4" style="width: 35%">商品種類</p>
    <div class="flex items-center justify-center gap-2" style="width: 35%">
      現價
      <img v-if="sortPriceActive === null" @click="; (sortPriceActive = !sortPriceActive), (sortScaleActive = null)"
        class="w-2" src="@/assets/icons/market/filter.svg" />
      <img v-else @click="; (sortPriceActive = !sortPriceActive), (sortScaleActive = null)" class="w-2"
        :class="{ 'transform rotate-180': !sortPriceActive }" src="@/assets/icons/market/sortIcon.svg" />
    </div>
    <div class="flex items-center justify-end gap-2 pr-4" style="width: 30%">
      漲幅
      <img v-if="sortScaleActive === null" @click="; (sortScaleActive = !sortScaleActive), (sortPriceActive = null)"
        class="w-2" src="@/assets/icons/market/filter.svg" />
      <img v-else @click="; (sortScaleActive = !sortScaleActive), (sortPriceActive = null)" class="w-2"
        :class="{ 'transform rotate-180': !sortScaleActive }" src="@/assets/icons/market/sortIcon.svg" />
    </div>
  </div>
  <div class="px-4 bg-[#2B2633]">
    <router-link v-for="(item, i) of sortList" :key="item.id" :to="`/trade/${item.id}`"
      class="flex items-center justify-between bg-main-100 text-sm py-4"
      :class="{ 'border-b border-white border-opacity-10': i !== list.length - 1 && border }">
      <p style="width: 35%">{{ item.title }}</p>
      <p class="text-center" :class="
        calculateScale(item.open, item.value) >= 0
          ? 'text-[#00C1A5]'
          : 'text-[#E81F4F]'
      " style="width: 35%">
        {{ +item.value }}
      </p>
      <div class="flex justify-end" style="width: 30%">
        <p class="py-2 text-center rounded-lg text-xs w-20" :class="[
          calculateScale(item.open, item.value) >= 0
            ? 'bg-[#00C1A5]'
            : 'bg-[#E81F4F]',
        ]">
          {{
            addSignSymbolAndPercentage(calculateScale(item.open, item.value))
          }}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import {
  addSignSymbolAndPercentage,
  calculateScale,
} from '@/utils/transfer-numbers'
const props = defineProps(['menu', 'border', 'sort'])
const currentType = ref(0)
const store = useStore()
const sortPriceActive = ref(null)
const sortScaleActive = ref(null)
const sortType = ref(0)
const typesList = computed(() => store.state.coinTypes)
const list = computed(() => JSON.parse(JSON.stringify(store.state.marketPrice)))

const setSortType = (e) => {
  sortType.value = e
  if (e === 0) {
    sortPriceActive.value = null
    sortScaleActive.value = null
  } else if (e === 1) {
    sortScaleActive.value = false
  } else {
    sortScaleActive.value = true
  }
}
const sortList = computed(() => {
  if (sortPriceActive.value === null && sortScaleActive.value === null) {
    return list.value
  } else {
    if (sortPriceActive.value === true) {
      return list.value.sort((a, b) => a.value - b.value)
    } else if (sortPriceActive.value === false) {
      return list.value.sort((a, b) => b.value - a.value)
    }
    if (sortScaleActive.value === true) {
      return list.value.sort((a, b) => a.scale - b.scale)
    } else if (sortScaleActive.value === false) {
      return list.value.sort((a, b) => b.scale - a.scale)
    }
  }
})
const symbolTitle = (symbol) => {
  return symbol.split()
}
</script>

<style></style>
